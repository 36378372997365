import React from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import useSWR from "swr";

import NewsletterForm from "../components/NewsletterForm";
import { centraGetProduct, centraPostWithCache } from "../lib/centra-api";
import { useCentraToken } from "../lib/utils";
import Link from "../components/Link";
import ContactForm from "../components/ContactForm";
import StoryblokImage from "../components/StoryblokImage";
import CentraProductImage from "../components/CentraProductImage";
import CentraProductLink from "../components/CentraProductLink";
import { BlockQuery } from "./DynamicBlock";

type Props = {
  background?: any;
  index: number;
  layout: string;
  left_link_text: string;
  link_text: boolean;
  raw_html: string;
  right_link_text: string;
  size: number;
  source?: any;
  product_source?: {
    plugin: "artilleriet-product-picker";
    productIds: string[];
    productTitles: string[];
  };
  text?: {
    plugin: "artilleriet-wysiwyg";
    content: string;
    image: string;
  };
  text_align?: string;
  text_color?: string;
  max_width?: string;
  contact_form?: any;
  newsletter_form?: any;
  video_with_controls?: boolean;
};

export default function Block({
  background,
  index,
  layout,
  left_link_text,
  link_text,
  raw_html,
  right_link_text,
  source: story,
  product_source,
  size,
  text,
  text_align,
  text_color,
  max_width,
  contact_form,
  newsletter_form,
  video_with_controls,
}: Props): React.ReactElement {
  const router = useRouter();
  const [token] = useCentraToken();

  let type;
  let centraUrl: string | null;

  if (product_source?.productIds?.[0]) {
    type = "product";
    const centraId = product_source?.productIds[0];
    centraUrl = `centraproduct://${centraId}/${router.locale}`;
  } else if (story) {
    type = story.content?.component;
  } else if (contact_form && contact_form.length) {
    type = "contact_form";
  } else if (newsletter_form && newsletter_form.length) {
    type = "newsletter_form";
  }

  const { data: centraData } = useSWR(centraUrl, (url) =>
    centraGetProduct(url, token)
  );

  let blockIndex = size > 1 ? "portrait" : "landscape";

  if (layout !== "unison") {
    switch (size) {
      case 3:
        if (layout === "alternative") {
          if (index < size - 1) {
            blockIndex = "landscape";
          }
        } else {
          if (index > 0) {
            blockIndex = "landscape";
          }
        }
        break;
      case 4:
        if (layout === "alternative") {
          if (index === 0) {
            blockIndex = "landscape Block-double";
          } else if (index === size - 1) {
            blockIndex = "portrait Block-double";
          }
        } else {
          if (index === 0) {
            blockIndex = "portrait Block-double";
          } else if (index === 1) {
            blockIndex = "landscape Block-double";
          }
        }
        break;
      case 5:
        if (layout === "alternative") {
          if (index === size - 1) {
            blockIndex = "portrait Block-double";
          }
        } else {
          if (index === 0) {
            blockIndex = "portrait Block-double";
          }
        }
        break;
    }
  }

  let mediaComponent;

  if (background?.filename) {
    const fileExt = background.filename.split(".").pop().toLowerCase();

    if (fileExt === "mp4") {
      if (video_with_controls) {
        mediaComponent = (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls muted autoPlay>
            <source src={background.filename} type="video/mp4" />
          </video>
        )
      } else {
        mediaComponent = (
          <video playsInline autoPlay loop muted src={background.filename} />
        )
      }
    } else {
      mediaComponent = <StoryblokImage fill asset={background} />;
    }
  } else if (story?.content?.featured_image?.filename) {
    mediaComponent = (
      <StoryblokImage fill asset={story.content.featured_image} />
    );
  } else if (centraData?.product?.media.portrait) {
    mediaComponent = (
      <CentraProductImage
        layout="fill"
        src={centraData.product.media.portrait[0]}
      />
    );
  }

  const classes = classNames(`Block Block-${blockIndex}`, {
    [`Block-${type === "product" || !mediaComponent ? "dark" : "light"}Color`]:
      !text_color || text_color === "auto",
    [`Block-${text_color}Color`]: text_color && text_color !== "auto",
  });

  const blockContent = raw_html || text.content;

  const content = (
    <>
      {mediaComponent && <div className={classNames("Block-image", {
        "video-with-controls": video_with_controls
      })}>{mediaComponent}</div>}

      <div
        className={classNames("Block-content", {
          [`${text_align}-align`]:
            text_align === "left" || text_align === "right",
          "no-pointers": video_with_controls
        })}
      >
        {blockContent ? (
          <div
            className={classNames("Block-text u-richText", {
              [`${max_width}-max-width`]: max_width,
            })}
            dangerouslySetInnerHTML={{
              __html: blockContent,
            }}
          />
        ) : null}

        {type === "product" ? (
          <>
            <div className="Block-title">
              {link_text ? left_link_text : centraData?.product?.name}
            </div>
            <div className="Block-action">
              {link_text ? right_link_text : "Shop"}
            </div>
          </>
        ) : null}

        {type !== "product" && story ? (
          <>
            <div className="Block-title">
              {link_text ? left_link_text : story.name}
            </div>
            <div className="Block-action">
              {link_text ? right_link_text : "Browse"}
            </div>
          </>
        ) : null}

        {type === "contact_form" ? (
          <>
            <div className="CustomerService-block--form">
              <ContactForm
                additionalData={{ subject: contact_form[0].subject }}
                forKitchenStudio={contact_form[0].for_kitchen_studio}
              />
            </div>
          </>
        ) : null}

        {type === "newsletter_form" ? (
          <>
            <div className="Blocks-newsletter">
              <NewsletterForm
                centered={newsletter_form[0].centered}
                title={newsletter_form[0].title}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );

  if (centraData?.product && !router.query._storyblok) {
    return (
      <CentraProductLink className={classes} product={centraData.product}>
        {content}
      </CentraProductLink>
    );
  }

  if (story && !router.query._storyblok) {
    return (
      <Link className={classes} href={`/${story.full_slug}`}>
        {content}
      </Link>
    );
  }

  return <div className={classes}>{content}</div>;
}

export const query: BlockQuery<Props> = async (
  props,
  story,
  locale,
  isWorkingInStoryblok
) => {
  if (props.product_source?.productIds?.[0]) {
    const centraId = props.product_source?.productIds[0];
    const centraUrl = `centraproduct://${centraId}/${locale}`;

    const centraData = await centraPostWithCache(
      `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products/`,
      "none",
      {
        products: [centraId],
        language: locale,
      }
    );

    if (!centraData.products?.[0]) {
      return {};
    }

    return {
      swrFallback: {
        [centraUrl]: { product: centraData.products?.[0] },
      },
    };
  }
};
