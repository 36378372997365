import React, { useContext, useEffect, useRef } from "react";

import { StoryContext } from "../pages/[...route]";
import StoryblokImage from "../components/StoryblokImage";
import Link from "../components/Link";
import { DynamicBlocks } from "./DynamicBlock";

export default function SingleJournal(): React.ReactElement {
  const context = useContext(StoryContext);
  const breadcrumbsRef = useRef<HTMLDivElement>();

  const story = context.story;
  const date = new Date(
    story.first_published_at || story.created_at
  ).toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
    day: "2-digit",
  });

  // This code is originally taken from https://ericeastwood.com/blog/
  useEffect(() => {
    if (!breadcrumbsRef.current) {
      return;
    }

    let latestScrollTop = 0;
    let lastReactedScrollTop = 0;
    let builtUpScrollTop = 0;
    let refHeight = 0;
    let refTranslateY = 0;
    let animationFrameId: number;

    function transformElement() {
      if (!breadcrumbsRef.current) {
        return;
      }

      builtUpScrollTop += latestScrollTop - lastReactedScrollTop;
      refHeight = breadcrumbsRef.current.offsetHeight;
      refTranslateY = clamp(-refHeight, 0, refTranslateY - builtUpScrollTop);

      breadcrumbsRef.current.style.transform = `translateY(${refTranslateY}px)`;

      builtUpScrollTop = 0;
      lastReactedScrollTop = latestScrollTop;
    }

    function clamp(min, max, value) {
      return Math.min(Math.max(value, min), max);
    }

    function handleScroll() {
      latestScrollTop = Math.max(0, window.scrollY);
      animationFrameId = requestAnimationFrame(transformElement);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [breadcrumbsRef.current]);

  return (
    <>
      <div className="Breadcrumbs" ref={breadcrumbsRef}>
        <ul className="Breadcrumbs-content">
          <li className="Breadcrumbs-item Breadcrumbs-item--bold">
            <Link href="/journal">Journal</Link>
          </li>

          <i className="Icon Icon--arrowRight" />

          <li className="Breadcrumbs-item">
            <Link href={"/" + story.full_slug}>{story.name}</Link>
          </li>
        </ul>
      </div>

      <section className="Blocks Blocks--1">
        <div className="Block Block-lightColor">
          {story.content.featured_image?.filename ? (
            <div className="Block-image">
              <StoryblokImage fill asset={story.content.featured_image} />
            </div>
          ) : null}

          <div className="Block-content">
            <div className="Block-text u-richText">
              <h5>{date}</h5>
              <h2>{story.name}</h2>
            </div>
          </div>
        </div>
      </section>

      <DynamicBlocks blocks={story.content.body} />
    </>
  );
}
