import React, { useContext } from "react";

import { getAllStoryblokStories } from "../lib/storyblok-api";
import { StoryContext } from "../pages/[...route]";
import Link from "../components/Link";
import StoryblokImage from "../components/StoryblokImage";
import { BlockQuery } from "./DynamicBlock";

export default function PageJournal(): React.ReactElement {
  const context = useContext(StoryContext);
  const stories = [];

  for (const queryResult of Object.values(context.queries)) {
    for (const story of queryResult.stories) {
      stories.push(story);
    }
  }

  return (
    <>
      {stories.map((story, index) => {
        const date = new Date(story.first_published_at).toLocaleDateString(
          "en-UK",
          { month: "short", year: "numeric", day: "2-digit" }
        );

        const featuredImage = story.content?.featured_image?.filename;
        const excerpt = story.content?.excerpt?.content;

        const text = (
          <Link
            className="Block Block-portrait Block-darkColor"
            href={"/" + story.full_slug}
          >
            <div className="Block-content">
              <div className="Block-text u-richText">
                <h4>{date}</h4>
                <h2>{story.name}</h2>
              </div>

              {excerpt && (
                <div
                  className="Block-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: excerpt,
                  }}
                />
              )}
            </div>
          </Link>
        );

        const image = (
          <a
            className="Block Block-portrait Block-lightColor"
            href={"/" + story.full_slug}
          >
            {featuredImage ? (
              <div className="Block-image">
                <StoryblokImage asset={story.content.featured_image} />
              </div>
            ) : null}
          </a>
        );

        return (
          <section className="Blocks--2" key={index}>
            {index % 2 == 0 ? (
              <>
                {image}
                {text}
              </>
            ) : (
              <>
                {text}
                {image}
              </>
            )}
          </section>
        );
      })}
    </>
  );
}

export const query: BlockQuery<void> = async (
  props,
  story,
  locale,
  isWorkingInStoryblok
) => {
  const stories = await getAllStoryblokStories({
    starts_with: "journal/",
    is_startpage: false,
    version: isWorkingInStoryblok ? "draft" : "published",
    language: locale === "sv" ? "sv" : "",
  });

  return {
    stories,
  };
};
