import React, { useState } from "react";
import { default as NextImage } from "next/image";
import classNames from "classnames";

function loader({ src, width }) {
  if (src.indexOf("a.storyblok.com") < 0) {
    return src;
  }

  const url = new URL(src);
  url.hostname = "img2.storyblok.com";
  url.pathname = `${width}x0${url.pathname}`;

  return url.toString();
}

type Props = {
  className?: string;
  fill?: boolean;
  sizes?: string;
  src?: string;
  alt?: string;
  asset?: StoryblokAsset;
} & ({ asset: StoryblokAsset } | { src: string });

export default function StoryblokImage({
  className,
  fill = false,
  sizes,
  src,
  alt,
  asset,
}: Props): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);

  src = src || asset?.filename;
  alt = alt || asset?.alt;

  if (!src) {
    return <></>;
  }

  const width = src.split("/")[5].split("x")[0];
  const height = src.split("/")[5].split("x")[1];

  return width && height ? (
    <NextImage
      className={classNames(className, {
        "is-loaded": isLoaded,
      })}
      src={src}
      alt={alt}
      layout={fill ? "fill" : "responsive"}
      width={fill ? null : width}
      height={fill ? null : height}
      objectFit={fill ? "cover" : null}
      loader={loader}
      sizes={sizes || "100vw"}
      onLoad={() => {
        setTimeout(() => setIsLoaded(true), 0);
      }}
    />
  ) : (
    <img className={className} src={src} alt={alt} />
  );
}
